import 'dopees-ui/lib/material-icon';
import 'dopees-ui-form/lib/box';
import './dope-log';
import { oauth2Config } from './dope-auth';
import { fetchStatus } from './dope-fetch-status';
import * as dope from 'dopees-core/lib/core';
import { repositories } from 'dopees-data/lib/repositories';
import { Uri } from 'dopees-core/lib/uri';
import { DopeRouterMixin } from 'dopees-ui/lib/router';
import { PolymerElement } from '@polymer/polymer/polymer-element';
import { customElement, property, observe } from '@polymer/decorators/lib/decorators';
import { HomeData, File, Gallery, OAuth2User, Quote, Appointment, Studio } from './contract';
import { mkTemplate } from 'dopees-ui/lib/templates';
import { KeyRestRepository } from 'dopees-data/lib/rest';
import { AnyProp, decoratedFetch } from 'dopees-core/lib/fetch';
import view from './nonillaa-admin/nonillaa-admin.pug';

(function() {
  let dataEndpoint: string;
  const dataEndpointMeta = document.querySelector('meta[name="service-endpoint-data"]');
  if (dataEndpointMeta) {
      dataEndpoint = dataEndpointMeta.getAttribute('content') || '';
  } else {
      if (console.warn) {
        console.warn('No data endpoint specified in meta.');
      }
      dataEndpoint = '';
  }

  let usersEndpoint: string;
  const usersEndpointMeta = document.querySelector('meta[name="service-endpoint-users"]');
  if (usersEndpointMeta) {
      usersEndpoint = usersEndpointMeta.getAttribute('content') || '';
  } else {
      if (console.warn) {
        console.warn('No users data endpoint specified in meta.');
      }
      usersEndpoint = '';
  }

  const tokenEndpointMeta = document.querySelector('meta[name="endpoint-oauth2"]');
  if (tokenEndpointMeta) {
    oauth2Config.tokenEndpoint = tokenEndpointMeta.getAttribute('content') || '';
  }

  const openidEndpointMeta = document.querySelector('meta[name="endpoint-openid"]');
  if (openidEndpointMeta) {
    oauth2Config.openidEndpoint = openidEndpointMeta.getAttribute('content') || '';
  }

  // médiatárnál automatikusan generálódik, ezért nem szükséges, amúgy meg ez kellene...
  // class Repository384 extends dope.RestRepository {
  //     constructor (options) { super(options); }
  //     get collectionEndpoint () { return `${this.endpoint}/${this.type}/384x384`; }
  // };

  repositories.register('file', () => new KeyRestRepository<File, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 2,
    type: 'file'
  }));

  repositories.register('quote', () => new KeyRestRepository<Quote, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 3,
    type: 'quote'
  }));

  repositories.register('appointment', () => new KeyRestRepository<Appointment, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 3,
    type: 'appointment'
  }));

  repositories.register('gallery', () => new KeyRestRepository<Gallery, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 3,
    type: 'gallery'
  }));

  repositories.register('studio', () => new KeyRestRepository<Studio, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 3,
    type: 'studio'
  }));

  repositories.register('homedata', () => new KeyRestRepository<HomeData, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 3,
    type: 'homedata'
  }));

  repositories.register('quote', () => new KeyRestRepository<HomeData, number>({
    endpoint: dataEndpoint,
    keyProperty: 'id',
    protocolVersion: 3,
    type: 'quote'
  }));

  // tslint:disable-next-line:max-line-length
  repositories.register('user', () => new KeyRestRepository<{ id: number, familyName: string, givenName: string}, number>({
    endpoint: usersEndpoint,
    keyProperty: 'id',
    protocolVersion: 2,
    type: 'user'
  }));
}());

export function stradd(a: any, b: any) {
  let x = String(a);
  let y = String(b);
  while (x.length < y.length) {
    x = '0' + x;
  }
  while (y.length < x.length) {
    y = '0' + y;
  }
  const l = x.length;
  let overflow = 0;
  let result = '';
  for (let i = l - 1; i >= 0; i = i - 1) {
    const c0 = parseInt(x[i], 10);
    const c1 = parseInt(y[i], 10);
    const c = c0 + c1 + overflow;
    overflow = Math.floor(c / 10);
    const j = c % 10;
    result = String(j) + result;
  }
  if (overflow) {
      result = String(overflow) + result;
  }
  return result;
}

const windowUrl = window.URL;
// tslint:disable-next-line:max-line-length
export async function downloadRestricted(uri: string, name: string, onError: (error: any) => void, init: RequestInit&AnyProp) {
  fetchStatus.inc();
  try {
    const response = await decoratedFetch(uri, init);
    if (!response.ok) { throw response; }
    const blob = await response.blob();
    const objectUrl = windowUrl.createObjectURL(blob);
    const a = document.createElement('a');
    (<HTMLBodyElement> document.querySelector('body')).appendChild(a);
    a.href = objectUrl;
    a.download = name || 'download';
    a.click();
    fetchStatus.dec();
    windowUrl.revokeObjectURL(objectUrl);
    setTimeout(() => a.remove(), 30000);
  } catch (e) {
    if (onError) {
        onError(e);
    } else {
        dope.pushErr(`Letöltés meghiúsult: ${uri}.`);
    }
  } finally {
    if (fetchStatus) {
      fetchStatus.dec();
    }
  }
}

export function createLocationProxy(source: Uri|string, isSuppressed: () => boolean, title?: string) {
  let tit: string;
  if (title) {
    tit = title;
  } else {
    const titleElement = document.querySelector('head title');
    tit = (titleElement && titleElement.innerHTML) || '';
  }
  return new Proxy(Uri.from(source), {
    get(uri, prop) {
      if ('queryParams' === prop) {
        (<any> uri).__queryParamsProxy = (<any> uri).__queryParamsProxy || new Proxy(uri.queryParams, {
          set(params, p, value) {
            if (!isSuppressed()) {
              if ('string' === typeof p) {
                if (0 === value || value) {
                  params[p] = value;
                } else {
                  delete params[p];
                }
                history.replaceState({
                  component: (uri.path.substring(1).replace('/', '-') || 'wlh-venues'),
                  arguments: params
                }, tit, uri.href);
              }
            }
            return true;
          }
        });
        return (<any> uri).__queryParamsProxy;
      }
      return uri[prop];
    },
    set(uri, prop, value) {
      if (!isSuppressed()) {
        if ('queryParams' === prop) {
          delete (<any> uri).__queryParamsProxy;
        }
        uri[prop] = value;
        history.replaceState({
          component: (uri.path.substring(1).replace('/', '-') || 'wlh-venues'),
          arguments: uri.queryParams
        }, tit, uri.href);
      }
      return true;
    }
  });
}

export function promiseMap<T, R>(source: T[], func: (item: T) => Promise<R>): Promise<R[]> {
  const result: R[] = [];
  const iterate = (i: number) => {
    if (source.length === i) {
      return Promise.resolve(result);
    }
    return Promise.resolve(func(source[i]))
      .then((resultN) => result.push(resultN))
      .then(() => iterate(i + 1));
  };
  return iterate(0);
}

@customElement('nonillaa-admin')
export class NonillaaAdmin extends DopeRouterMixin(PolymerElement) {
  static get template() { return mkTemplate(view); }

  @property({ type: Object, notify: true })
  user?: OAuth2User;

  @property({ type: String })
  dataEndpoint: string;

  @property({ type: String })
  uploadEndpoint: string;

  constructor() {
    super();
    const dataEndpointMeta = document.querySelector('meta[name="service-endpoint-data"]');
    if (dataEndpointMeta) {
      const endpoint = dataEndpointMeta.getAttribute('content');
      if (endpoint) {
        this.dataEndpoint = endpoint;
        this.uploadEndpoint = endpoint + '/upload';
      } else {
        throw new Error('data endpoint is empty');
      }
    } else {
      throw new Error('no data endpoint defined');
    }
  }

  get defaultComponent() { return 'nonillaa-home'; }

  __eq(a: any, b: any) { return a === b; }

  __formatUser(user: OAuth2User) { return user ? `${user.family_name} ${user.given_name}` : ''; }

  _resolveComponentPath(name: string) {
    return `/${name}.js`;
  }

  onLogOutClick() {
    window.localStorage.clear();
    window.location.reload();
  }

  @observe('user')
  userChanged() {
    if (this.currentPage && this.shadowRoot) {
      const currentElement = this.shadowRoot.querySelector(this.currentPage.component);
      if (currentElement) {
          currentElement.dispatchEvent(new CustomEvent('init', { detail: this.currentPage.arguments }));
      }
    }
  }
}

// betűtípusok betöltése...
(function() {
  if ((<any> document).fonts && (<any> document).FontFace) {
    const fonts = [
      // style: normal, weight: 400
      {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/ek4gzZ-GeXAPcSbHtCeQI_esZW2xOQ-xsNqO47m55DA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          unicodeRange: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/mErvLBYg_cXG3rLvUsKT_fesZW2xOQ-xsNqO47m55DA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          unicodeRange: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/-2n2p-_Y08sg57CNWQfKNvesZW2xOQ-xsNqO47m55DA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          unicodeRange: 'U+1F00-1FFF'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/u0TOpm082MNkS5K0Q4rhqvesZW2xOQ-xsNqO47m55DA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          unicodeRange: 'U+0370-03FF'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/NdF9MtnOpLzo-noMoG0miPesZW2xOQ-xsNqO47m55DA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          unicodeRange: 'U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/Fcx7Wwv8OzT71A3E1XOAjvesZW2xOQ-xsNqO47m55DA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/CWB0XYA8bzo0kSThX0UTuA.woff2',
        opts: {
          style: 'normal',
          weight: 400,
          // tslint:disable-next-line:max-line-length
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215'
        }
      },
      // style: normal, weight: 700
      {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4AMP6lbBP.woff2',
        opts: {
          style: 'normal',
          weight: 700,
          unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4AMP6lQ.woff2',
        opts: {
          style: 'normal',
          weight: 700,
          // tslint:disable-next-line:max-line-length
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215'
        }
      },
      // style: normal, weight: 500
      {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fChc4AMP6lbBP.woff2',
        opts: {
          style: 'normal',
          weight: 500,
          unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF'
        }
      }, {
        family: 'Roboto',
        url: 'https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4AMP6lQ.woff2',
        opts: {
          style: 'normal',
          weight: 500,
          // tslint:disable-next-line:max-line-length
          unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215'
        }
      }
    ];
    // Betöltés Font Loading API-val
    // tslint:disable-next-line:max-line-length
    fonts.forEach((font) => (<any> document).fonts.add(new (<any> window).FontFace(font.family, 'url(' + font.url + ')', font.opts)));
  } else {
    (<any> window).WebFontConfig = {
      google: {
        families: [ 'Roboto:400,500,700:cyrillic,cyrillic-ext,greek,greek-ext,latin-ext' ]
      }
    };
    // Betöltés webfont loader segítségével.
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1.6.26/webfont.js';
    const head = document.querySelector('head');
    if (head) {
      head.appendChild(script);
    }
  }
}());
